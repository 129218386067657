<template>
  <div class="w-full h-auto">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <header
      class="mb-4 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-between gap-3"
    >
      <div
        class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4"
        v-if="idCompany === 1"
      >
        <a-button
          type="primary"
          shape="round"
          :block="true"
          class="w-64"
          @click="openFilter"
        >
          <span class="flex items-start justify-start font-sans text-xs">
            <a-icon type="filter" :style="{ fontSize: '16px' }" class="mr-2" />
            <b>Filtro de búsqueda</b>
          </span>
        </a-button>
      </div>
      <div class="w-full sm:max-w-sm">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div
            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <a-input-search
            placeholder="Buscar un usuario en particular"
            class="rounded-full"
            style="width: 100%"
            v-model="filterModel.responsable"
            @keyup.enter="onFilters"
          />
        </div>
      </div>
    </header>

    <div class="table-component">
      <a-table
        class="table-component"
        :columns="columns"
        :data-source="data"
        :scroll="{ x: '1220px' }"
      >
        <span slot="fecha_reporte" slot-scope="text, record">{{
          record.fecha_reporte
        }}</span>
        <span slot="responsable" slot-scope="text, record">{{
          record.responsable
        }}</span>
        <span
          :class="
            record.estado_ev == 'Borrador'
              ? 'text-blue-600 bg-blue-200'
              : 'text-green-600 bg-green-100'
          "
          slot="estado_ev"
          slot-scope="text, record"
        >
          {{ record.estado_ev }}
        </span>
        <div
          class="flex justify-evenly align-center"
          slot="action"
          slot-scope="text, record"
        >
          <button
            :disabled="record.estado_ev == 'Gestionado' ? true : false"
            :class="
              record.estado_ev !== 'Gestionado'
                ? 'bg-blue-900 py-2 px-5 rounded-lg text-white text-sm'
                : 'bg-gray-300 py-2 px-5 rounded-lg text-white text-sm'
            "
            @click="goToReportCreation(record)"
          >
            Gestionar
          </button>
          <button
            class="text-sm text-blue h-min"
            @click="goToReportDetail(record)"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{ fontSize: '22px' }"
            />
          </button>
          <button
            :disabled="record.estado_ev == 'Gestionado' ? true : false"
            class="text-sm text-blue h-min"
            @click="goToReportEdit(record)"
          >
            <a-icon
              type="edit"
              theme="twoTone"
              :two-tone-color="
                record.estado_ev == 'Gestionado' ? '#b5b5b5' : '#3366cc'
              "
              :style="{ fontSize: '22px' }"
            />
          </button>
        </div>
      </a-table>
    </div>

    <a-drawer
      title="Filtros de Búsqueda"
      placement="right"
      :visible="filterDrawer"
      @close="filterDrawer = false"
      width="512"
    >
      <a-row>
        <a-col :span="24">
          <BaseSelect
            v-model="filterModel.state"
            label="Estado de Gestión"
            :selectOptions="[
              { id: 'Por Gestionar', name: 'Por Gestionar' },
              { id: 'Borrador', name: 'Borrador' },
              { id: 'Gestionado', name: 'Gestionado' },
            ]"
          />
        </a-col>
        <a-col :span="24">
          <BaseRadio
            v-model="typeDate"
            label="Fecha de creación del informe"
            :radioOptions="[
              { id: 1, name: 'Una sola fecha' },
              { id: 2, name: 'Periodo de Fechas' },
            ]"
            @onChangeValue="onChangeDateRadio"
          />
        </a-col>
        <a-col v-if="filterDateOption === 2" :span="24">
          <BaseRangeDate @notify-dates="onChangeDateRange" />
        </a-col>
        <a-col v-else-if="filterDateOption === 1" :span="24">
          <BaseDate lable="Fecha" v-model="filterModel.filter_date" />
        </a-col>
        <a-col :span="24">
          <div class="w-100 d-flex mt-8 items-center justify-center">
            <a-button @click="onFilters" block size="large" type="primary">
              <span class="flex items-start justify-start font-sans">
                <a-icon
                  type="sliders"
                  :style="{ fontSize: '16px' }"
                  class="mr-2"
                />
                <b>Filtrar</b>
              </span>
            </a-button>
          </div>
        </a-col>
      </a-row>
    </a-drawer>
  </div>
</template>

<script>
import TableComponent from "@/components/UI/Table/TableComponent.vue";
import AnnualReportServices from "@/feature/annual_report_ev/data/services/annual_report_services";

const columns = [
  {
    slots: { title: "fecha_reporte" },
    title: "Fecha de Reporte",
    dataIndex: "fecha_reporte",
    key: "fecha_reporte",
    scopedSlots: { customRender: "fecha_reporte" },
  },
  {
    slots: { title: "responsable" },
    title: "Responsable",
    dataIndex: "responsable",
    key: "responsable",
    scopedSlots: { customRender: "responsable" },
  },
  {
    slots: { title: "estado_ev" },
    title: "Estado",
    dataIndex: "estado_ev",
    key: "estado_ev",
    scopedSlots: { customRender: "estado_ev" },
  },
  {
    slots: { title: "action" },
    title: "Acciones",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: {
    TableComponent,
  },
  data() {
    return {
      loading: false,
      filterDateOption: null,
      filterDrawer: false,
      filterModel: {
        start_date: "",
        end_date: "",
        entity_id: "",
        filter_date: "",
        state: "",
        responsable: "",
      },
      typeDate: "",
      count: 0,
      page: 1,
      pageSize: 100,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      columns: columns,
      data: [],
    };
  },
  async created() {
    this.getReports();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    data() {},
    "$route.query.page": function () {
      this.getData();
    },
  },
  computed: {
    idCompany: function () {
      return this.$store.state.session?.user?.id_company?.id;
    },
  },
  methods: {
    async getReports() {
      this.loading = true;
      const response = await AnnualReportServices.getReports();
      this.data = response.data.data;
      this.loading = false;
    },
    goToReportCreation(record) {
      console.log(record);
      this.$router.push(
        `/dashboard/register-annual-report/manage/${record.document_id}?step=1`
      );
      sessionStorage.setItem(
        "info_informe",
        JSON.stringify({
          responsable: record.responsable,
          fecha_reporte: record.fecha_reporte,
        })
      );
      // document_id
    },
    goToReportDetail(record) {
      this.$router.push(
        `/dashboard/register-annual-report/detail/${record.document_id}?step=1`
      );
      sessionStorage.setItem(
        "info_informe",
        JSON.stringify({
          responsable: record.responsable,
          fecha_reporte: record.fecha_reporte,
        })
      );
    },
    goToReportEdit(record) {
      this.$router.push(
        `/dashboard/register-annual-report/edit/${record.document_id}?step=1`
      );
      console.log(record);
      sessionStorage.setItem(
        "info_informe",
        JSON.stringify({
          responsable: record.responsable,
          fecha_reporte: record.fecha_reporte,
        })
      );
    },
    openFilter() {
      this.filterDrawer = true;
    },
    async onFilters() {
      this.loading = true;
      const response = await AnnualReportServices.setFilter(this.filterModel);
      this.data = response.data.data;
      this.loading = false;
    },
    onChangeDateRadio(value) {
      this.filterModel.start_date = "";
      this.filterModel.end_date = "";
      this.filterDateOption = value;
    },
    onChangeDateRange(value) {
      this.filterModel.filter_date = "";
      this.filterModel.start_date = value[0];
      this.filterModel.end_date = value[1];
    },
  },
};
</script>

<style scoped>
button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.btn-gray {
  background: #e7e7e7;
  color: black;
}

.btn-primary {
  background: #004884;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #309d7e;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #004884;
}

.btn-success-light {
  background: #e5fbf5;
  color: #309d7e;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search > input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search > button {
  border-radius: 10px;
}

.table {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  font-weight: bold;
}

.table > div {
  flex-wrap: wrap;
}

.table > div > div {
  border-bottom: 1px solid black;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar > div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #309d7e;
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}
</style>